import bird from "../../../../posts/three-things-in-arizona/bird.jpg";
import crater from "../../../../posts/three-things-in-arizona/crater.jpg";
import open from "../../../../posts/three-things-in-arizona/open.png";
import stars from "../../../../posts/three-things-in-arizona/stars.jpg";
import wide from "../../../../posts/three-things-in-arizona/wide.jpg";
import * as React from 'react';
export default {
  bird,
  crater,
  open,
  stars,
  wide,
  React
};