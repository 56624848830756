// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-index-page-js": () => import("./../../../src/templates/blogIndexPage.js" /* webpackChunkName: "component---src-templates-blog-index-page-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

